import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetCurrencyNetwork } from "../../../../redux/actions/CryptoTransaction/GetCurrencyNetwork";
import { GetDepositAddress } from "../../../../redux/actions/CryptoTransaction/GetDepositAddress";
import { StoreDepositAddress } from "../../../../redux/actions/CryptoTransaction/StoreDepositAddress";
import { GetValidationErrors } from "../../../../helper";
import CancelTransactionModal from "../../../Modals/CancelTransactionModal";
import { PrimaryButton, SmallButton } from "../../../Buttons";
import ErrorMessage from "../../../Common/ErrorMessage";
import Label from "../../../Label";
import SelectAccountDropdown from "../../../Common/SelectAccountDropdown";
import CryptoNetworksDropdown from "../../CryptoNetworksDropdown";
import { CopyIcon, DropdownCheckIcon, DropdownCrossIcon } from "../../../Icons";
import { CryptoDepositAddressSkeleton } from "../../../Skeleton";
import QRCode from "./partials/QRCode";
import { setActiveAccount } from "../../../../redux/actions/home/ActiveAccountAction";

const BitpaceDeposit = () => {
  const cryptoAccounts = useSelector(
    (state) => state.client.clientInfo.crypto_accounts
  );
  const { activeAccount } = useSelector((state) => state.activeAccount);
  const [cryptoCurrencyNetworks, setCryptoCurrencyNetworks] = useState([]);
  const [cryptoNetworkLoading, setCryptoNetworkLoading] = useState(false);
  const [cryptoNetwork, setCryptoNetwork] = useState("");
  const [copiedText, setCopiedText] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [message, setIsMessage] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const [walletDetails, setWalletDetails] = useState("");
  const [address, setAddress] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const networks = async () => {
      const data = {
        currency_code: activeAccount.currency_code,
        account_id: activeAccount.id,
      };
      setCryptoCurrencyNetworks([]);
      setCryptoNetworkLoading(true);
      const networks = await dispatch(GetCurrencyNetwork(data));
      if (networks.status === "DECLINED") {
        setIsError(networks.message);
        setCryptoNetwork("");
        setAddress("");
        setCryptoNetworkLoading(false);
        return;
      } else if (networks.data && networks.data.length === 0) {
        setIsError(
          `No network available for the ${activeAccount.currency_code} currency.`
        );
      } else {
        setCryptoCurrencyNetworks(networks.data);
        setCryptoNetwork(networks.data[0]);
      }
      setCryptoNetworkLoading(false);
    };
    setCryptoNetwork("");
    setAddress("");
    setIsError("");
    if (activeAccount) {
      networks();
    }
    // eslint-disable-next-line
  }, [activeAccount]);

  useEffect(() => {
    const getDepositAddress = async () => {
      const data = {
        account_id: activeAccount.id,
        network: cryptoNetwork,
      };
      try {
        setIsLoading(true);
        let address = await dispatch(GetDepositAddress(data));
        if (address.success) {
          setWalletDetails(address.data);
          setAddress(address.data);
        } else if (!address.success) {
          setAddress("");
          setWalletDetails("");
        }
        setIsLoading(false);
      } catch (error) {
        setIsError("Something went wrong. Please try again later.");
      }
    };
    if (cryptoNetwork) {
      setIsError(address.message);
      getDepositAddress();
    }
    // eslint-disable-next-line
  }, [activeAccount, cryptoNetwork]);

  const storeDepositAddress = async () => {
    if (activeAccount.title === walletDetails.title && cryptoNetwork === walletDetails.network) {
      setAddress(walletDetails);
      return
    }

    const data = {
      account_id: activeAccount.id,
      network: cryptoNetwork,
    };
    try {
      setIsLoading(true);
      let storeAddress = await dispatch(StoreDepositAddress(data));
      if (storeAddress.success) {
        setWalletDetails(storeAddress.data);
        setAddress(storeAddress.data);
        setIsLoading(false);
        return;
      } else if (storeAddress.errors) {
        setIsError(GetValidationErrors(storeAddress.errors));
        setWalletDetails(null);
      } else {
        setIsError(storeAddress.message);
        setWalletDetails(null);
      }
      
      setIsLoading(false);
    } catch (error) {
      setIsError("Something went wrong. Please try again later.");
    }
  };

  const handleCopy = (text, field) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);
    setIsCopied(true);
    setIsMessage(`${field} copied`);
    setTimeout(() => {
      setIsCopied(false);
      setIsMessage("");
    }, 3000);
  };

  return (
    <main>
      <div className="pb-4">
        <div className="max-w-4xl mx-auto py-6">
          <div className="bg-dropdown-popup-bg rounded-3xl shadow-box-shadow p-6 max-[360px]:p-4">
            <div className="flex flex-col w-full">
              <div className="flex justify-between py-4">
                <h1 className="text-2xl font-semibold text-body-text">
                  Crypto Deposit
                </h1>
                <CancelTransactionModal
                  navigateTo="/crypto"
                  contextLabel="Cancel deposit"
                >
                  <SmallButton text="Cancel" back={true} />
                </CancelTransactionModal>
              </div>
            </div>
            {isError && (
              <ErrorMessage message={isError} onClose={() => setIsError("")} />
            )}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Label
                  className="block text-base font-medium text-body-text mb-2"
                  htmlFor="Crypto Account"
                  text="Crypto Account"
                />
                <div className="space-y-2">
                  <SelectAccountDropdown
                    title={"Select crypto account"}
                    setAccount={(account) => {
                      dispatch(setActiveAccount(account));
                    }}
                    accounts={cryptoAccounts}
                    slectedAccount={activeAccount}
                  />
                </div>
              </div>
              <div>
                <Label
                  className="block text-base font-medium text-body-text mb-2"
                  htmlFor="cryptoAccount"
                  text="Crypto Network"
                />
                <div className="space-y-2">
                  <CryptoNetworksDropdown
                    id="cryptoAccount"
                    networks={cryptoCurrencyNetworks}
                    value={cryptoNetwork}
                    onChange={setCryptoNetwork}
                    label="Select crypto network"
                    placeholder="Select crypto network"
                    loading={cryptoNetworkLoading}
                  />
                </div>
              </div>
            </div>

            {address === "" && (
              <div className="grid grid-cols-2 gap-4 sm:grid-cols-6 mt-3">
                <PrimaryButton
                  text="Submit"
                  onClick={storeDepositAddress}
                  disabled={
                    cryptoNetwork === "" || activeAccount === "" || isloading
                  }
                />
              </div>
            )}

            {address !== "" && (
              <div className="mt-6 pt-2 px-4 bg-option-list-hover dark:bg-input-bg rounded-3xl">
                <div className="flex items-start justify-between w-full ">
                  <Label
                    className="block text-base font-medium text-body-text"
                    htmlFor="cryptoAddressdetails"
                    text="Crypto address details"
                  />
                  <div
                    onClick={() => setAddress("")}
                    className="flex items-start hover:bg-option-list-selected p-2 rounded-full cursor-pointer"
                  >
                    <DropdownCrossIcon />
                  </div>
                </div>

                <QRCode
                  walletAddress={address.crypto_address}
                  logo={address.logo}
                />

                <div className="flex items-center justify-center">
                  <div className="grid grid-cols-1 md:grid-cols-2 py-6 gap-4 mx-auto">
                    <Item htmlFor="cryptoAddressdetails" title="Crypto address">
                      <div
                        onClick={() =>
                          handleCopy(address.crypto_address, "Crypto Address")
                        }
                        className="flex space-x-2 cursor-pointer"
                      >
                        <span className="text-paragraph-text text-sm">
                          {address.crypto_address}
                        </span>
                        {isCopied && copiedText === address.crypto_address ? (
                          <DropdownCheckIcon
                            fillColor="fill-primary-item"
                            className="bg-[#70CC4A] rounded-full"
                          />
                        ) : (
                          <CopyIcon />
                        )}
                      </div>
                    </Item>

                    {address.destination_tag && (
                      <Item htmlFor="destinationTag" title="Destination tag">
                        <div
                          onClick={() =>
                            handleCopy(
                              address.destination_tag,
                              "Destination Tag"
                            )
                          }
                          className="flex space-x-2 cursor-pointer"
                        >
                          <span className="text-paragraph-text text-sm">
                            {address.destination_tag}
                          </span>
                          {isCopied &&
                          copiedText === address.destination_tag ? (
                            <DropdownCheckIcon
                              fillColor="fill-primary-item"
                              className="bg-[#70CC4A] rounded-full p-0.5"
                            />
                          ) : (
                            <CopyIcon />
                          )}
                        </div>
                      </Item>
                    )}

                    <Item htmlFor="accountTitle" title="Account Title">
                      <span className="flex items-center space-x-2 text-paragraph-text">
                        <img
                          src={address.logo}
                          alt="logo"
                          className="rounded-full w-8 h-8"
                        />
                        <span>{address.title}</span>
                      </span>
                    </Item>

                    <Item htmlFor="network" title="Network">
                      <span className="text-paragraph-text">
                        {address.network}
                      </span>
                    </Item>

                    <Item htmlFor="email" title="Email">
                      <span className="text-paragraph-text">
                        {address.email}
                      </span>
                    </Item>
                  </div>
                </div>
              </div>
            )}
            {isloading && <CryptoDepositAddressSkeleton />}
            {message && (
              <div
                className="absolute bottom-2 left-1/2 transform -translate-x-1/2 w-auto px-4 py-2 border border-transparent
           dark:border-divider-border rounded-lg bg-[#0F0F0F] shadow-md"
              >
                <p className="text-white text-sm font-normal tracking-[0.07px]">
                  {message}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

const Item = ({ htmlFor, title, children }) => {
  return (
    <div className="overflow-scroll hide-scrollbar">
      <Label
        className="block text-base font-medium text-body-text mb-2"
        htmlFor={htmlFor}
        text={title}
      />
      {children}
    </div>
  );
};

export default BitpaceDeposit;
